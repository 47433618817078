<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="用户名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="所属角色" prop="RoleID">
        <el-select v-model="form.RoleID" style="width: 100%">
          <el-option
            v-for="item in RoleList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="登录账号">
        <el-input v-model="form.Account" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="性  别">
        <el-radio-group v-model="form.Sex">
          <el-radio label="男" size="large">男</el-radio>
          <el-radio label="女" size="large">女</el-radio>
          <el-radio label="保密" size="large">保密</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="年  龄">
        <el-input-number v-model="form.Age" :min="0" :max="150" />
      </el-form-item>
      <el-form-item label="职  位">
        <el-input v-model="form.Position" autocomplete="off"> </el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.Phone" autocomplete="off"> </el-input>
      </el-form-item>
      <el-form-item label="地图中心">
        <el-input v-model="form.MapCenter" autocomplete="off">
          <template #append>
            <el-button :icon="Search" @click="showMap"></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="地图级别">
        <el-input-number
          v-model="form.MapLevel"
          autocomplete="off"
          :min="3"
          :max="21"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="到期时间" v-if="props.dialogTitle == '添加用户'">
        <el-date-picker
          v-model="form.ExpireTime"
          type="date"
          :disabled-date="disabledDate"
          :shortcuts="shortcuts"
          style="width: 100%"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
    <MapDialog
      v-model="MapDialogVisible"
      v-if="MapDialogVisible"
      @SetXYData="SetXYData"
      :XY="form.MapCenter"
      :Level="form.MapLevel"
    ></MapDialog>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { GetRoleFillList } from '@/api/Role'
import { AddUser, EditUser } from '@/api/User'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import { Search } from '@element-plus/icons-vue'
import MapDialog from './mapDialog.vue'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  }
})
const formRef = ref(null)
var form = ref({ Sex: '保密', MapLevel: 10, Age: 0 })
const dialogVisible = ref(false)
const MapDialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '用户名称不能为空', trigger: 'blur' }],
  RoleID: [{ required: true, message: '所属角色不能为空', trigger: 'blur' }]
})
const RoleList = ref([])
const shortcuts = [
  {
    text: '今天',
    value: new Date()
  },
  {
    text: '明天',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24)
      return date
    }
  },
  {
    text: '一周',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
      return date
    }
  }
]

const disabledDate = (time) => {
  return time.getTime() < Date.now()
}
onMounted(() => {
  GetRoleFillList()
    .then((res) => {
      RoleList.value.length = 0
      res.Data.forEach((element) => {
        RoleList.value.push(element)
      })
    })
    .catch((err) => {})
})
const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  if (props.dialogTitle === '修改用户信息') {
    form.value = props.dialogRowValue
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加用户') {
        AddUser(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditUser(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
const showMap = () => {
  MapDialogVisible.value = true
}
const SetXYData = (xy, level) => {
  form.value.MapCenter = xy
  form.value.MapLevel = level
}
</script>
