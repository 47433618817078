<template>
  <el-dialog
    title="坐标选择"
    :model-value="dialogVisible"
    @close="close"
    @opened="initMap"
    width="800px"
    height="500px"
    draggable
  >
    <div class="top">
      <el-input
        placeholder="请输入搜索内容"
        v-model="inputPlace"
        id="suggestId"
        @keyup.enter="search"
      >
      </el-input>
      <el-button @click="search">搜索</el-button>
      <el-input
        v-model="xy"
        placeholder="坐标值"
        style="width: 200px; margin-left: 10px"
      ></el-input>
      <el-input
        v-model="level"
        placeholder="级别"
        input-style="width: 50px"
      ></el-input>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="SaveInfo">确 定</el-button>
      </span>
    </div>
    <div id="map" class="map" style="width: 100%; height: 400px"></div>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
const props = defineProps({
  XY: {
    type: String,
    default: '',
    required: true
  },
  Level: {
    type: Number,
    default: 10
  }
})
const dialogVisible = ref(false)
const emits = defineEmits(['update:modelValue', 'SetXYData'])
const close = () => {
  emits('update:modelValue', false)
}
const SaveInfo = () => {
  emits('SetXYData', xy.value, level.value)
  close()
}
onMounted(() => {
  xy.value = props.XY
  level.value = props.Level
})
//地图相关
var inputPlace = ref('')
const xy = ref('')
const level = ref(10)
var map
var local
var searchPlces = reactive([])
const initMap = () => {
  map = new BMapGL.Map('map') // 创建Map实例
  let x = 118.126668
  let y = 39.629471
  if (!isNull(xy.value)) {
    let mapcenterArr = xy.value.split(',')
    if (mapcenterArr.length == 2) {
      x = mapcenterArr[0]
      y = mapcenterArr[1]
    }
  }
  map.centerAndZoom(new BMapGL.Point(x, y), level.value) // 初始化地图,设置中心点坐标和地图级别
  map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
  var navi3DCtrl = new BMapGL.NavigationControl3D() // 添加3D控件
  map.addControl(navi3DCtrl)
  map.addEventListener('click', function (e) {
    xy.value = e.latlng.lng.toFixed(6) + ',' + e.latlng.lat.toFixed(6)
    level.value = map.getZoom().toFixed(2)
  })
  var options = {
    renderOptions: { map: map }
  }
  local = new BMapGL.LocalSearch(map, options)
}
const search = () => {
  local.search(inputPlace.value)
}
const querySearch = (queryString, cb) => {
  local.search(queryString)
  setTimeout(() => {
    cb(searchPlces)
  }, 3000)
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.top {
  position: relative;
  top: -10px;
}
.el-input {
  width: 240px;
}
.dialog-footer {
  float: right;
}
</style>
